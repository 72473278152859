import { Controller } from "@hotwired/stimulus";

function setValue(selector: HTMLInputElement | null, value: string) {
  if (selector) {
    selector.value = value
  }
}

function setText(selector: HTMLElement | null, value: string) {
  if (selector) {
    selector.innerText = value
  }
}

class ContentSelectionModalController extends Controller {
  declare modalTarget: HTMLElement;
  declare templateTarget: HTMLTemplateElement;
  declare hasTemplateTarget: Boolean;
  declare listTarget: HTMLElement;
  declare hasListTarget: Boolean;

  static targets = ["list", "modal", "template"];

  #renderInput(id: string, title: string, category: string) {
    if (!this.templateElement) {
      console.warn("Template not found")
      return
    }

    if (!this.listElement) {
      console.warn("List not found")
      return
    }

    const tempNode = document.importNode(this.templateElement.content, true);
    const li = tempNode.querySelector("li")
    if (li) {
      li.id = "form_" + id;
      setText(li.querySelector(".title"), title);
      setText(li.querySelector(".category"), `(${category})`);
      setValue(li.querySelector("input"), id);
      this.listElement.appendChild(li);
    }
  }

  escape(e: KeyboardEvent) {
    if (e.key === "Escape" && !this.modalTarget.classList.contains("hidden")) {
      this.modalTarget.classList.add("hidden");
    }
  }

  onRemove(e: Event) {
    e.preventDefault();
    const button = e.currentTarget as HTMLElement
    const li = button?.parentElement?.parentElement;
    const id = li?.querySelector("input")?.value
    document.querySelector(`div[data-id='${id}'] .check`)?.classList?.add("hidden");
    li?.parentElement?.removeChild(li);
  }

  hideModal(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.modalTarget.classList.add("hidden");
  }

  showModal(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.modalTarget.classList.remove("hidden");
  }

  onRowSelect(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    const row = e.currentTarget as HTMLElement
    const id = row.dataset.id;
    const category = row.dataset.category;
    const title = row.dataset.title;

    row.querySelector(".check")?.classList?.toggle("hidden");
    const input = document.getElementById("form_" + id);
    if (input && input.parentElement) {
      input.parentElement.removeChild(input);
    } else {
      this.#renderInput(id || "", title || "", category || "")
    }
  }

  get listElement(): HTMLElement | null {
    return this.hasListTarget ? this.listTarget : document.querySelector("#content_selection_list")
  }

  get templateElement(): HTMLTemplateElement | null {
    return this.hasTemplateTarget ? this.templateTarget : document.querySelector("#content_selection_template")
  }
}

export default ContentSelectionModalController;

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dataList", "checkbox"];

  declare dataListTarget: HTMLElement;
  declare checkboxTarget: HTMLInputElement;
  declare checkboxTargets: [HTMLInputElement];

  selected(e: Event & { currentTarget: HTMLInputElement }) {
    const tagLabel = e.currentTarget.value
    const option: HTMLElement | null = this.dataListTarget.querySelector(`option[value="${tagLabel}"]`)

    const checkbox = this.checkboxTargets.find(checkbox => checkbox.value === option?.dataset.id)

    if (checkbox) {
      checkbox.checked = true
    }

    e.currentTarget.value = ""
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["removeContent", "downloadButton"];

	declare downloadButtonTarget: HTMLButtonElement;
	declare removeContentTarget: HTMLInputElement;

	removeFile(e: Event) {
		e.preventDefault();
		this.removeContentTarget.checked = true;

		const target = e.target as HTMLButtonElement;
		target.disabled = true;
		target.classList.add("py-4", "mb-4");
		target.innerText = "File has been marked for removal. Undo by pressing 'Back'";

		this.downloadButtonTarget.parentElement?.removeChild(this.downloadButtonTarget);
	}
}

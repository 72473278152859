import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	toggleRoles = () => {
		let checkedIds: string[] = [];

		this.element
			.querySelectorAll<HTMLInputElement>('input[name="user[role_ids][]"]')
			.forEach((c) => {
				if (!c.checked) {
					return;
				}

				c.parentElement?.querySelectorAll("li").forEach((li) => {
					const id = li.dataset.id;
					if (c.checked && id) {
						checkedIds.push(id);
					}
				});
			});

		this.element
			.querySelectorAll<HTMLInputElement>("[name='user[track_ids][]'")
			.forEach((check) => {
				if (check.checked && !check.disabled) {
					return;
				}
				check.classList.remove("opacity-75");
				check.parentElement?.classList.remove("cursor-not-allowed");
				check.checked = false;
				check.disabled = false;
			});

		checkedIds.forEach((id) => {
			const check = document.querySelector<HTMLInputElement>(
				`input[value="${id}"]`
			);
			if (check && !check.checked) {
				check.classList.add("opacity-75");
				check.parentElement?.classList.add("cursor-not-allowed");
				check.checked = true;
				check.disabled = true;
			}
		});
	};

	connect() {
		this.toggleRoles();
	}
}

import { Controller } from "@hotwired/stimulus";
// @ts-ignore
import Clappr from "clappr";
// @ts-ignore
import Video360 from "clappr-video360";

export default class extends Controller {
  declare heightValue: String;
  declare player: any;
  declare widthValue: String;
  private observer: IntersectionObserver | null = null;

  static values = {
    height: { type: String, default: "360" },
    width: { type: String, default: "640" },
  }

  connect() {
    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        threshold: 0.1, // Trigger when at least 10% of the element is visible
      }
    );
    this.observer.observe(this.element);
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.player) {
      this.player.destroy();
    }
  }
  private handleIntersection(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.initializePlayer();
        this.observer?.unobserve(this.element);
      }
    });
  }

  private initializePlayer() {
    document.body.classList.remove("w-full", "h-full");

    // @ts-ignore
    const source = this.element.dataset.src;
    // @ts-ignore
    const is360 = this.element.dataset.threeSixty === "true";
    let plugins = {};
    if (is360) {
      plugins = { container: [Video360] };
    }

    this.player = new Clappr.Player({
      playback: {
        preload: "metadata",
        disableContextMenu: false,
        controls: false,
        crossOrigin: "use-credentials",
        playInline: true,
        minimumDvrSize: null,
        externalTracks: [],
        hlsjsConfig: {},
        shakaConfiguration: {},
      },
      source,
      width: this.widthValue,
      height: this.heightValue,
      plugins,
    });

    this.player.attachTo(this.element);
    this.player.core.toggleFullscreen = () => { };
    this.player.getPlugin("click_to_pause").disable();
  }
}

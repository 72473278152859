import { Controller } from "@hotwired/stimulus";

interface Page {
	render(n: any): { promise: Promise<void> };
	getViewport(a: any): VisualViewport;
}

interface PDFDoc {
	numPages: number;
	getPage(n: number): Promise<Page>;
}

interface JSLib {
	GlobalWorkerOptions: { workerSrc: string };
	getDocument(url: string): { promise: Promise<PDFDoc> };
}

export default class extends Controller {
	static targets = ["canvas", "url", "prev", "next", "pageNum", "pageCount"];

	declare canvasTarget: HTMLCanvasElement;
	declare urlTarget: HTMLInputElement;

	declare prevTarget: HTMLButtonElement;
	declare nextTarget: HTMLButtonElement;
	declare pageNumTarget: HTMLSpanElement;
	declare pageCountTarget: HTMLSpanElement;

	declare pdfDoc: PDFDoc;
	declare pdfjsLib: JSLib;
	pageRendering = false;
	pageNumPending: number | null = null;
	pageNum = 1;
	ctx: null | CanvasRenderingContext2D = null;

	connect() {
		// @ts-ignore
		this.pdfjsLib = window["pdfjs-dist/build/pdf"];
		this.pdfjsLib.GlobalWorkerOptions.workerSrc =
			"https://mozilla.github.io/pdf.js/build/pdf.worker.js";
		this.ctx = this.canvasTarget.getContext("2d");
		this.download();
	}

	download() {
		this.pdfjsLib.getDocument(this.urlTarget.value).promise.then((pdfDoc_) => {
			this.pdfDoc = pdfDoc_;
			this.pageCountTarget.textContent = String(this.pdfDoc.numPages);
			this.renderPage(this.pageNum);
		});
	}

	renderPage(num: number) {
		this.pageRendering = true;
		const scale = 2;
		// Using promise to fetch the page
		this.pdfDoc.getPage(num).then((page) => {
			var viewport = page.getViewport({ scale: scale });
			this.canvasTarget.height = viewport.height;
			this.canvasTarget.width = viewport.width;

			// Render PDF page into canvas context
			var renderContext = {
				canvasContext: this.ctx,
				viewport,
			};
			var renderTask = page.render(renderContext);

			// Wait for rendering to finish
			renderTask.promise.then(() => {
				this.pageRendering = false;
				if (this.pageNumPending !== null) {
					// New page rendering is pending
					this.renderPage(this.pageNumPending);
					this.pageNumPending = null;
				}
			});
		});

		// Update page counters
		this.pageNumTarget.textContent = String(num);
	}

	queueRenderPage(num: number) {
		if (this.pageRendering) {
			this.pageNumPending = num;
		} else {
			this.renderPage(num);
		}
	}

	onPrevPage() {
		if (this.pageNum <= 1) {
			return;
		}
		this.pageNum--;
		this.queueRenderPage(this.pageNum);
	}

	onNextPage() {
		if (this.pageNum >= this.pdfDoc.numPages) {
			return;
		}
		this.pageNum++;
		this.queueRenderPage(this.pageNum);
	}
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["submit", "error"];
	static values = {
		limit: Number,
	};

	declare submitTarget: HTMLInputElement;
	declare errorTarget: HTMLInputElement;
	declare limitValue: number;

	onCheck = (e: Event) => {
		const checked = this.element.querySelectorAll(
			"input:checked:enabled"
		).length;

		const disabled = !(checked <= this.limitValue);
		this.submitTarget.disabled = disabled;
		if (disabled) {
			this.errorTarget.classList.remove("hidden");
		} else {
			this.errorTarget.classList.add("hidden");
		}
	};

	connect() {
		this.submitTarget.disabled = true;
	}
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["title", "body", "button"];
	static values = {
		title: String,
		body: String,
		button: String,
		classes: String,
	};

	declare titleValue: string;
	declare bodyValue: string;
	declare buttonValue: string;
	declare classesValue: string;

	declare titleTarget: HTMLDivElement;
	declare bodyTarget: HTMLDivElement;
	declare buttonTarget: HTMLButtonElement;

	close() {
		(this.element as HTMLDialogElement).close();
	}

	connect() {
		const dialog = this.element as HTMLDialogElement;
		this.buttonTarget.innerText = this.buttonValue;
		if (this.classesValue.length > 0) {
			this.buttonTarget.classList.add(...this.classesValue.split(" "));
		}
		this.titleTarget.innerText = this.titleValue;
		this.bodyTarget.innerText = this.bodyValue;

		document.body.classList.add("overflow-hidden");

		dialog.showModal();
		dialog.addEventListener("close", () => {
			document.body.classList.remove("overflow-hidden");
		});
		dialog.addEventListener("click", function (event) {
			const rect = dialog.getBoundingClientRect();
			const isInDialog =
				rect.top <= event.clientY &&
				event.clientY <= rect.top + rect.height &&
				rect.left <= event.clientX &&
				event.clientX <= rect.left + rect.width;
			if (!isInDialog) {
				dialog.close();
			}
		});
	}
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = {
		url: String,
	};

	declare urlValue: string;

	connect() {
		this.element.remove();
		window.Turbo.visit(this.urlValue);
	}
}

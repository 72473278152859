import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["webUrl", "fileInput", "preview", "removeContent", "url"];

	declare webUrlTarget: HTMLInputElement;
	declare fileInputTarget: HTMLInputElement;
	declare removeContentTarget: HTMLInputElement;
	declare urlTarget: HTMLInputElement;
	declare previewTarget: Element;

	removeReading(e: Event) {
		e.preventDefault();
		window
			.confirmMethod("Remove File?", e.currentTarget as HTMLElement)
			.then((yes) => {
				if (yes) {
					this.removeContentTarget.checked = true;
					this.fileInputTarget.value = "";
					this.fileInputTarget.files = null;
					this.fileInputTarget.closest("form")?.requestSubmit();
				}
			});
	}

	fileAttached({ target }: { target: HTMLInputElement }) {
		const files = target.files || [];
		if (files.length > 0) {
			this.removeContentTarget.checked = false;
		}
	}
}

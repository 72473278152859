import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static debounces = ["change"];

  connect(): void {
    useDebounce(this);
  }

  change(e: Event) {
    // @ts-ignore
    this.element.requestSubmit();
  }
}

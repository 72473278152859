import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["info"];

	declare infoTarget: HTMLDivElement;

	resetPlanOption(e: Event) {
		this.element.querySelectorAll("form input[type='radio']").forEach((r) => {
			// @ts-ignore
			r.checked = false;
		});
		this.element
			.querySelectorAll("form input[type='radio'] + label")
			.forEach((l) => {
				l.classList.remove("hidden");
				l.querySelectorAll(".choose-button").forEach((b) =>
					b.classList.remove("hidden")
				);
				l.querySelectorAll("button").forEach((b) => b.classList.add("hidden"));
			});

		this.infoTarget.classList.add("hidden");
	}

	planSelected(e: Event) {
		const plan = e.currentTarget as HTMLInputElement;
		this.element
			.querySelectorAll("form input[type='radio'] + label")
			.forEach((l) => {
				if (l.getAttribute("for") !== plan.id) {
					l.classList.add("hidden");
				} else {
					l.querySelectorAll(".choose-button").forEach((b) =>
						b.classList.add("hidden")
					);
					l.querySelectorAll("button").forEach((b) =>
						b.classList.remove("hidden")
					);
				}
			});

		this.infoTarget.classList.remove("hidden");
	}

	connect() {
		if (document.querySelectorAll(".field_with_errors").length === 0) {
			this.infoTarget.classList.add("hidden");
		}
	}
}

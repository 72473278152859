import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	submit(e: Event) {
		const form = (e.target as HTMLInputElement).closest("form");
		if (!form) {
			return;
		}
		if (!form.checkValidity()) {
			const list = form.querySelectorAll(":invalid");
			const errors = new Set();
			errors.add("• Form is invalid");

			for (const item of list) {
				if (item.id.includes("course_readings")) {
					errors.add("• Course reading errors");
				}
				if (item.id.includes("course_questions")) {
					errors.add("• Quiz errors");
				}
			}

			window.toast({
				type: "error",
				text: Array.from(errors).join(`\n`),
			});
		}
	}
}

import Trix from "trix";
import { Controller } from "@hotwired/stimulus";

type TrixEditor = HTMLElement & { toolbarElement: HTMLElement };

export default class extends Controller {
  static classes = ["container"];

  declare readonly containerClass: string;
  declare readonly hasContainerClass: boolean;

  _editorElement: TrixEditor | null = null;

  headings = [
    { name: "heading2", tag: "h2", title: "Heading 2" },
    { name: "heading3", tag: "h3", title: "Heading 3" },
    { name: "heading4", tag: "h4", title: "Heading 4" },
  ];

  connect() {
    this.setupGeneralConfig();
    this.setupHeadingConfig();
    this.setupHighlightConfig();

    const editorElement = document.createElement("trix-editor");
    editorElement.setAttribute("input", this.element.id);
    editorElement.classList.add("text-editor");
    editorElement.classList.add(this.containerClass);

    this.element.insertAdjacentHTML("afterend", editorElement.outerHTML);

    this.editorElement = document.querySelector(
      `#${this.element.id} ~ trix-editor`
    );

    this.editorElement?.addEventListener("trix-initialize", (event) => {
      this.handleSetupHeadingToolbar(event);
      this.handleSetupHighlightButton(event);
    });

    this.editorElement?.addEventListener("trix-file-accept", (event) =>
      event.preventDefault()
    );
  }

  get editorElement(): TrixEditor | null {
    return this._editorElement;
  }

  set editorElement(element: TrixEditor | null) {
    this._editorElement = element;
  }

  disconnect(): void {
    this.editorElement?.removeEventListener(
      "trix-initialize",
      this.handleSetupHeadingToolbar
    );

    this.editorElement?.removeEventListener(
      "trix-initialize",
      this.handleSetupHighlightButton
    );
  }

  setupGeneralConfig() {
    Trix.config.blockAttributes.default.tagName = "p";
  }

  handleSetupHeadingToolbar(event: Event) {
    this.setupHeadingToolbar((event.target as TrixEditor)?.toolbarElement);
  }

  handleSetupHighlightButton(event: Event) {
    this.setupHighlightButton((event.target as TrixEditor)?.toolbarElement);
  }

  setupHeadingToolbar(toolbarElement: HTMLElement): void {
    let siblingButton = toolbarElement.querySelector<HTMLButtonElement>(
      `[data-trix-attribute="heading1"]`
    );

    if (siblingButton === null) {
      return;
    }

    siblingButton.setAttribute("title", "Heading 1");
    siblingButton.innerText = "H1";
    siblingButton.classList.remove(
      "trix-button--icon",
      "trix-button--icon-heading1"
    );

    this.headings.forEach((heading) => {
      const button = document.createElement("button");
      button.type = "button";
      button.classList.add("trix-button");
      button.dataset.trixAttribute = heading.name;
      button.innerText = heading.tag.toUpperCase();
      button.setAttribute("tabindex", "-1");
      button.setAttribute("title", heading.title);

      siblingButton?.insertAdjacentHTML("afterend", button.outerHTML);

      siblingButton = toolbarElement.querySelector(
        `[data-trix-attribute="${heading.name}"]`
      );
    });
  }

  setupHighlightButton(toolbarElement: HTMLElement) {
    const textToolsGroup = toolbarElement.querySelector(".trix-button-group.trix-button-group--text-tools")

    const button = document.createElement("button");
    button.type = "button";
    button.classList.add("trix-button", "trix-button--icon", "trix-button--icon-highlight");
    button.dataset.trixAttribute = "highlight";
    button.dataset.trixKey = "y";
    button.setAttribute("tabindex", "-1");
    button.setAttribute("title", "Highlight");

    textToolsGroup?.insertAdjacentHTML("beforeend", button.outerHTML)
  }

  setupHeadingConfig() {
    this.headings.forEach((heading) => {
      Trix.config.blockAttributes[heading.name] = {
        tagName: heading.tag,
        terminal: true,
        breakOnReturn: true,
        group: false,
      };
    });
  }

  setupHighlightConfig() {
    Trix.config.textAttributes.highlight = {
      tagName: "mark",
    };
  }
}

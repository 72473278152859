import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["list", "template"];

	declare listTarget: HTMLUListElement;
	declare templateTarget: HTMLTemplateElement;

	onRemoveNew = (e: Event) => {
		e.preventDefault();
		(e.currentTarget as HTMLButtonElement).parentElement?.remove();
	};

	onRemove = (e: Event) => {
		const button = e.currentTarget as HTMLDivElement;
		button.dataset.classes = "btn-red";
		button.dataset.button = "Remove Document";

		window.confirmMethod("Remove Document?", button).then((yes) => {
			if (yes) {
				button.classList.add("hidden");
				button.closest("li")?.classList?.add("line-through");
				const check =
					button.nextElementSibling?.querySelector<HTMLInputElement>(
						"input[type=checkbox]"
					);
				if (check) {
					check.checked = true;
				}
			}
		});
	};

	addReading = (e: Event) => {
		e.preventDefault();
		const content = this.templateTarget.content;
		const li = document.importNode(content, true);
		this.listTarget.appendChild(li);
	};

	readingsAdded = (e: Event) => {
		const target = e.target as HTMLInputElement;
		const files = target.files;
		if (!files) {
			return;
		}

		this.listTarget
			.querySelectorAll("li.reading-template")
			.forEach((e) => e.remove());

		for (let file of files) {
			const content = this.templateTarget.content;
			const li = document.importNode(content, true);
			const title = li?.querySelector<HTMLDivElement>("div.title");
			if (title) {
				title.innerText = file.name;
			}
			const category = li?.querySelector<HTMLDivElement>("div.category");
			if (category) {
				category.innerText = "(waiting to upload)";
			}

			this.listTarget.appendChild(li);
		}
	};
}

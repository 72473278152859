import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const el = this.element as HTMLElement;
    const confirmText = el.dataset.confirm || "Are you sure?";

    this.element.addEventListener("click", function (event) {
      let confirmed = confirm(confirmText);
      if (!confirmed) {
        event?.preventDefault();
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus";

declare global {
  interface Window {
    API: {
      LMSInitialize: () => void;
      LMSFinish: () => void;
      LMSGetValue: (key: string) => string;
      LMSSetValue: (key: string, value: string) => void;
      LMSCommit: () => void;
      LMSGetLastError: () => number;
      LMSGetErrorString: () => string;
      LMSGetDiagnostic: () => string;
    };
  }
}

export default class extends Controller {
  [key: string]: any;

  static targets = [
    "lessonLocation",
    "lessonStatus",
    "scoreRaw",
    "suspendData",
    "updateProgress"
  ];

  static values = {
    launchData: Object,
    lessonLocation: String,
    lessonMode: String,
    lessonStatus: String,
    scoreRaw: Number,
    suspendData: String,
  }

  declare launchDataValue: object
  declare lessonLocationTarget: HTMLInputElement
  declare lessonLocationValue: string
  declare lessonModeValue: "browse" | "normal" | "review"
  declare lessonStatusTarget: HTMLInputElement
  declare lessonStatusValue: "completed" | "browsed" | "failed" | "incomplete" | "not attempted" | "passed"
  declare scoreRawTarget: HTMLInputElement
  declare scoreRawValue: number;
  declare suspendDataTarget: HTMLInputElement
  declare suspendDataValue: string;
  declare updateProgressTarget: HTMLFormElement;

  initialize() {
    const self = this;

    window.API = {
      LMSInitialize: () => true,
      LMSFinish: () => self.updateLMS(),
      LMSGetValue: (key) => self.getValue(key),
      LMSSetValue: (key, value) => self.setValue(key, value),
      LMSCommit: () => { self.updateLMS() },
      LMSGetLastError: function () {
        return 0;
      },
      LMSGetErrorString: function () {
        return "";
      },
      LMSGetDiagnostic: function () {
        return "";
      },
    };
  }

  cmiKeyValueMap: { [key: string]: string } = {
    "cmi.core.lesson_location": "lessonLocationValue",
    "cmi.core.lesson_mode": "lessonModeValue",
    "cmi.core.lesson_status": "lessonStatusValue",
    "cmi.core.score.raw": "scoreRawValue",
    "cmi.launch_data": "launchDataValue",
    "cmi.suspend_data": "suspendDataValue",
  }

  getValue(key: string): string {
    const mappedValue = this.cmiKeyValueMap[key];
    console.log({ key, mappedValue, value: this[mappedValue] })
    return mappedValue ? this[mappedValue] : ""
  }

  setValue(key: string, value: string): void {
    const mappedValue = this.cmiKeyValueMap[key];
    console.log({ key, mappedValue, value })
    if (mappedValue) {
      this[mappedValue] = value;
    }
  }

  updateLMS(): void {
    const formData = new FormData(this.updateProgressTarget);
    const formDataEntries = Object.fromEntries(formData.entries());
    console.log("Committing data");
    console.log(formDataEntries);
    this.updateProgressTarget.requestSubmit();
  }

  suspendDataValueChanged(): void {
    this.suspendDataTarget.value = this.suspendDataValue;
    this.updateLMS()
  }

  lessonLocationValueChanged(): void {
    this.lessonLocationTarget.value = this.lessonLocationValue;
  }

  lessonStatusValueChanged(): void {
    this.lessonStatusTarget.value = this.lessonStatusValue;
  }

  scoreRawValueChanged(): void {
    this.scoreRawTarget.value = this.scoreRawValue.toString();
  }
}

// Import and register all your controllers from the importmap under controllers/*
import { Application } from "@hotwired/stimulus";

import TextareaAutogrow from "stimulus-textarea-autogrow";
import accordion from "controllers/accordion_controller";
import assessment_form from "controllers/assessment_form_controller";
import ce_course from "controllers/ce_course";
import company_form from "controllers/company_form_controller";
import confirm from "controllers/confirm_controller";
import confirm_dialog from "controllers/confirm_dialog_controller";
import content_selection_modal from "controllers/content_selection_modal_controller";
import course_readings from "controllers/course_readings";
import enrollment from "controllers/enrollment";
import ExpandController from "controllers/expand_controller";
import nav from "controllers/nav_controller";
import pdf from "controllers/pdf_controller";
import question_form from "controllers/question_form_controller";
import quiz_form from "controllers/quiz_form_controller";
import reading_form from "controllers/reading_form_controller";
import redirect from "controllers/redirect_controller";
import scorm_form from "controllers/scorm_form_controller";
import scorm_rte from "controllers/scorm_rte_controller";
import sign_up from "controllers/sign_up_controller";
import skill_form from "controllers/skill_form_controller";
import sortable from "controllers/sortable_controller";
import submit_on_change from "controllers/submit_on_change";
import tags from "controllers/tags_controller";
import text_editor from "controllers/text_editor_controller";
import track_role_toggle from "controllers/track_role_toggle";
import update_checklist from "controllers/update_checklist_controller";
import upload_preview from "controllers/upload_preview";
import upload_progress from "controllers/upload_progress_controller";
import vendor_form from "controllers/vendor_form";
import video from "controllers/video_controller";
import {
	Alert,
	Dropdown,
	Popover,
	Tabs,
	Toggle,
} from "tailwindcss-stimulus-components";

declare global {
	interface Window {
		Stimulus: ReturnType<typeof Application.start>;
		confirmMethod: (text: string, el: HTMLElement) => Promise<boolean>;
		Turbo: {
			visit(url: string, options?: object): void;
			setConfirmMethod(
				fn: (text: string, el: HTMLElement) => Promise<boolean>
			): void;
		};
		toast: ({
			text,
			type,
		}: {
			text: string;
			type?: "error" | "notice" | "info";
		}) => void;
	}
}

window.Stimulus = Application.start();
window.Stimulus.debug = !window.location.hostname.includes("pyxis");

window.Stimulus.register("accordion", accordion);
window.Stimulus.register("alert", Alert);
window.Stimulus.register("assessment-form", assessment_form);
window.Stimulus.register("ce-course", ce_course);
window.Stimulus.register("company-form", company_form);
window.Stimulus.register("confirm", confirm);
window.Stimulus.register("confirm-dialog", confirm_dialog);
window.Stimulus.register("content-selection-modal", content_selection_modal);
window.Stimulus.register("course-readings", course_readings);
window.Stimulus.register("dropdown", Dropdown);
window.Stimulus.register("enrollment", enrollment);
window.Stimulus.register("expand", ExpandController);
window.Stimulus.register("nav", nav);
window.Stimulus.register("pdf", pdf);
window.Stimulus.register("popover", Popover);
window.Stimulus.register("question-form", question_form);
window.Stimulus.register("quiz-form", quiz_form);
window.Stimulus.register("reading-form", reading_form);
window.Stimulus.register("redirect", redirect);
window.Stimulus.register("scorm-form", scorm_form);
window.Stimulus.register("scorm-rte", scorm_rte);
window.Stimulus.register("sign-up", sign_up);
window.Stimulus.register("skill-form", skill_form);
window.Stimulus.register("sortable", sortable);
window.Stimulus.register("submit-on-change", submit_on_change);
window.Stimulus.register("tabs", Tabs);
window.Stimulus.register("tags", tags);
window.Stimulus.register("text-editor", text_editor);
window.Stimulus.register("textarea-autogrow", TextareaAutogrow);
window.Stimulus.register("toggle", Toggle);
window.Stimulus.register("track-role-toggle", track_role_toggle);
window.Stimulus.register("update-checklist", update_checklist);
window.Stimulus.register("upload-preview", upload_preview);
window.Stimulus.register("upload-progress", upload_progress);
window.Stimulus.register("vendor-form", vendor_form);
window.Stimulus.register("video", video);

const confirmMethod = (
	title: string,
	element: HTMLElement
): Promise<boolean> => {
	return new Promise((resolve) => {
		const dialogTemplate = document.querySelector(
			"template#confirm_template"
		) as HTMLTemplateElement;
		const temp = document.importNode(dialogTemplate.content, true);
		const dialog = temp.querySelector("dialog") as HTMLDialogElement;
		dialog.dataset.confirmDialogTitleValue = title || "Confirm";
		dialog.dataset.confirmDialogBodyValue =
			element.dataset.body || "Are you sure?";
		const cls = element.dataset.classes || "btn-primary";
		dialog
			.querySelector('[data-confirm-dialog-target="button"]')
			?.classList.remove("btn-primary");
		dialog
			.querySelector('[data-confirm-dialog-target="button"]')
			?.classList.add(...cls.split(" "));

		dialog.dataset.confirmDialogButtonValue = element.dataset.button || "OK";
		dialog.addEventListener("close", () => {
			resolve(dialog?.returnValue === "confirm");
		});
		document.body.appendChild(temp);
	});
};

window.toast = ({ text, type = "notice" }) => {
	const flashTemplate = document.querySelector("template#flash_template") as
		| HTMLTemplateElement
		| undefined;
	if (flashTemplate) {
		const classes = {
			notice: "bg-green-200 text-green-600",
			error: "bg-red-100 text-red-800",
			info: "bg-blue-100 text-blue-800",
		}[type];
		const temp = document.importNode(flashTemplate.content, true);
		temp
			.querySelectorAll("div > div > div > div > svg")
			.forEach((e) => e.classList?.add("hidden"));
		temp.querySelector(`[data-id=${type}]`)?.classList?.remove("hidden");
		const alertText = temp.querySelector(`[data-id=alert-text]`) as
			| HTMLElement
			| undefined;
		if (alertText) {
			alertText.innerText = text;
		}
		temp
			.querySelector(`[data-controller=alert]`)
			?.classList?.add(...classes.split(" "));

		let toastTarget = document.body;
		document.querySelectorAll("dialog").forEach((dialog) => {
			if (dialog.open) {
				toastTarget = dialog;
			}
		});

		toastTarget.appendChild(temp);
	}
};

window.confirmMethod = confirmMethod;
window.Turbo.setConfirmMethod(confirmMethod);

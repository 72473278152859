import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["menu", "downloadButton"];

	declare menuTarget: HTMLButtonElement;

	toggleMenu(e: Event) {
		const button = e.currentTarget as HTMLButtonElement;
		button.querySelectorAll("svg").forEach((s) => s.classList.toggle("hidden"));
		this.menuTarget.classList.toggle("hidden");
	}
}

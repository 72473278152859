import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["answersContainer"];

	declare answersContainerTarget: HTMLDivElement;

	addAnswer() {
		const templateTarget = document.querySelector(
			'[data-assessment-form-target="template"]'
		) as HTMLTemplateElement;
		const answer = document.importNode(templateTarget.content, true);

		const id = (
			this.answersContainerTarget.parentElement?.parentElement
				?.querySelector("input[type=hidden]")
				?.getAttribute("name") || ""
		).replace(/\D/gi, "");
		answer.querySelectorAll("input").forEach((field) => {
			this.#setAnswerId(field, id);
		});
		this.answersContainerTarget.appendChild(answer);
		this.#sortAnswerValues();
	}

	removeAnswer(e: Event) {
		const button = e.currentTarget as HTMLButtonElement;
		if (button) {
			button.parentElement?.parentElement?.removeChild(button.parentElement);
		}
		this.#sortAnswerValues();
	}

	connect(): void {
		this.answersContainerTarget.addEventListener(
			"onSortEnd",
			this.#sortAnswerValues.bind(this)
		);
	}

	disconnect(): void {
		this.answersContainerTarget.removeEventListener(
			"onSortEnd",
			this.#sortAnswerValues.bind(this)
		);
	}

	#sortAnswerValues() {
		this.answersContainerTarget
			.querySelectorAll("input[type=radio]")
			.forEach((radio, i) => {
				(radio as HTMLInputElement).value = String(i);
			});
	}

	#setAnswerId(el: Element, id: string) {
		const newName = el.getAttribute("name")?.replace(/\d/gi, id) || "";
		const newId = el.getAttribute("id")?.replace(/\d/gi, id) || "";
		el.setAttribute("name", newName);
		el.setAttribute("id", newId);
	}
}

import { Controller } from "@hotwired/stimulus";

const MB_15 = 15720000;
const MB_1 = 1050000;

function bytesToSize(bytes: number) {
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	if (bytes === 0) return "n/a";
	const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
	if (i === 0) return `${bytes} ${sizes[i]})`;
	return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export default class extends Controller {
	imagePreview(e: Event) {
		const target = e.target as HTMLInputElement;
		const max = target.dataset.limit || MB_15;
		const files = target.files || [];
		if (files.length > 0) {
			const file = files[0];
			if (file.size > Number(max)) {
				const text = `File size too large. Maximum size is ${bytesToSize(
					Number(max)
				)}, while your file is ${bytesToSize(file.size)}.`;
				window.toast({ text, type: "error" });
				target.value = "";
				e.preventDefault();
				return;
			}
			const src = URL.createObjectURL(file);
			const div = target.parentElement?.parentElement?.querySelector(
				".preview-container"
			) as HTMLDivElement;
			div?.classList?.remove("hidden");

			const preview =
				(div.querySelector("img") as HTMLImageElement) ||
				(div.querySelector("video") as HTMLVideoElement);
			if (preview) {
				preview.src = src;
				if (preview instanceof HTMLVideoElement) {
					preview.currentTime = 15;
				}
			}
		}
	}
}

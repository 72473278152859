import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
	connect() {
		new Sortable(this.element as HTMLElement, {
			animation: 450,
			direction: "vertical",
			easing: "cubic-bezier(0, 1, 0, 1)",
			onEnd: () => {
				const event = new Event('onSortEnd');
				this.element.dispatchEvent(event);
			}
		});
	}
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	declare buttonClass: string;
	declare buttonTarget: HTMLElement;
	declare class: string;
	declare hasButtonClass: boolean;
	declare hasHiddenClass: boolean;
	declare hiddenClass: string;
	declare itemTargets: HTMLElement[];

	static targets = ["item", "button"];
	static classes = ["hidden", "button"];

	connect(): void {
		this.class = this.hasHiddenClass ? this.hiddenClass : "hidden";
	}

	toggle(): void {
		this.itemTargets.forEach((item) => {
			item.classList.toggle(this.class);
		});

		if (this.hasButtonClass) {
			this.buttonTarget.classList.toggle(this.buttonClass);
		}
	}

	show(): void {
		this.itemTargets.forEach((item) => {
			item.classList.remove(this.class);
		});

		if (this.hasButtonClass) {
			this.buttonTarget.classList.add(this.buttonClass);
		}
	}

	hide(): void {
		this.itemTargets.forEach((item) => {
			item.classList.add(this.class);
		});

		if (this.hasButtonClass) {
			this.buttonTarget.classList.remove(this.buttonClass);
		}
	}
}

import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["template", "assessmentContainer", "sortButton"];
  static values = {
    sorting: Boolean,
  };

  declare templateTarget: HTMLTemplateElement;
  declare assessmentContainerTarget: HTMLDivElement;
  declare sortButtonTarget: HTMLDivElement;
  declare sortable: Sortable | undefined;
  declare sortingValue: boolean;

  addAssessment() {
    const assessmentId = String(
      this.assessmentContainerTarget.querySelectorAll("fieldset").length
    );
    const assessment = document.importNode(this.templateTarget.content, true);
    assessment.querySelectorAll("input").forEach((field) => {
      this.#setAssessmentId(field, assessmentId);
    });
    this.assessmentContainerTarget.appendChild(assessment);
    this.#sortAssessmentOrder();
  }

  removeAssessment(e: Event) {
    const fieldset = (e.currentTarget as HTMLButtonElement).parentElement
      ?.parentElement;
    const persisted = fieldset?.dataset?.persisted;
    const destroyCheckbox = (
      e.currentTarget as HTMLButtonElement
    ).parentElement?.querySelector("input[type=checkbox]") as HTMLInputElement;
    if (fieldset && destroyCheckbox) {
      destroyCheckbox.checked = true;
      fieldset.classList.add("hidden");
    }

    if (persisted === "false") {
      fieldset?.remove();
    }

    this.#sortAssessmentOrder();
  }

  sortingValueChanged() {
    const buttonText = this.sortButtonTarget.querySelector(
      "span"
    ) as HTMLElement;
    const use = this.sortButtonTarget.querySelector("svg use") as HTMLElement;
    let text = "";
    let icon = "";

    if (this.sortingValue) {
      text = "Done";
      icon = (use.getAttribute("xlink:href") || "").replace(
        "bars-arrow-down",
        "x-circle"
      );
    } else {
      text = "Sort Assessments";
      icon = (use.getAttribute("xlink:href") || "").replace(
        "x-circle",
        "bars-arrow-down"
      );
    }

    buttonText.innerText = text;
    use.setAttribute("xlink:href", icon);

    const sortingClasses = ["animate-pulse", "cursor-move"];
    this.assessmentContainerTarget
      .querySelectorAll("fieldset:not(.hidden) [data-hide-when-sorting=true]")
      .forEach((el) => {
        if (this.sortingValue) {
          el.classList.add("hidden");
          el.parentElement?.classList.add(...sortingClasses);
        } else {
          el.classList.remove("hidden");
          el.parentElement?.classList.remove(...sortingClasses);
        }
      });

    this.assessmentContainerTarget
      .querySelectorAll("fieldset:not(.hidden) [data-show-when-sorting=true]")
      .forEach((el) => {
        if (this.sortingValue) {
          el.classList.remove("hidden");
        } else {
          el.classList.add("hidden");
        }
      });

    if (this.sortingValue) {
      this.sortable = new Sortable(
        this.assessmentContainerTarget as HTMLElement,
        {
          animation: 450,
          direction: "vertical",
          easing: "cubic-bezier(0, 1, 0, 1)",
          onEnd: () => {
            const event = new Event("onSortEnd");
            this.assessmentContainerTarget.dispatchEvent(event);
          },
        }
      );
    } else {
      this.sortable?.destroy();
    }
  }

  sortAssessments(e: Event) {
    this.sortingValue = !this.sortingValue;
  }

  connect(): void {
    this.assessmentContainerTarget.addEventListener(
      "onSortEnd",
      this.#sortAssessmentOrder.bind(this)
    );
  }

  disconnect(): void {
    this.assessmentContainerTarget.removeEventListener(
      "onSortEnd",
      this.#sortAssessmentOrder.bind(this)
    );
  }

  #sortAssessmentOrder() {
    this.assessmentContainerTarget
      .querySelectorAll("fieldset:not(.hidden) input[data-sort-order='true']")
      .forEach((sort, i) => {
        (sort as HTMLInputElement).value = String(i + 1);
      });
  }

  #setAssessmentId(el: Element, id: string) {
    const newName = el.getAttribute("name")?.replace(/\d/gi, id) || "";
    const newId = el.getAttribute("id")?.replace(/\d/gi, id) || "";
    el.setAttribute("name", newName);
    el.setAttribute("id", newId);

    if (newName.includes("sort_order")) {
      el.setAttribute("value", id);
    }
  }
}
